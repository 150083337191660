<template>
  <div id="baoche"
       :class="{advance: carType == 'advance'}">
    <div class="car-guide">
      <div class="prompt"
           :class="{advance: carType == 'advance'}">
        <div class="prompt-text">
          1.「包车」服务仅支持微信端，可通过关注「youthswitch」公众号登录使用
        </div>
        <div class="prompt-text">
          2.为保障您的账号安全和操作方便，家长号仍然是 Youth 官方账号
        </div>
        <div class="prompt-text">
          3.成为「包车」司机后可自行再额外邀请最多 6
          名乘客同行，享有邀请、移除成员等相关权限
        </div>
      </div>
      <div class="chart">
        <div class="step">
          <div class="step-icon-wrap">
            <div class="step-icon-outer"
                 :class="{advance: carType == 'advance'}">
              <div class="step-icon">
                <svg t="1615791949805"
                     class="icon"
                     viewBox="0 0 1027 1024"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     p-id="6035"
                     width="200"
                     height="200">
                  <path d="M403.269632 110.592a742.912 742.912 0 0 1 219.648 0c51.2 10.24 102.4 18.432 150.528 31.744a107.52 107.52 0 0 1 75.776 71.168q31.232 88.064 65.536 174.592c4.096 9.728 16.384 24.064 23.552 23.04A75.776 75.776 0 0 1 1025.349632 471.552a66.048 66.048 0 0 1 0 31.744l-58.368 15.872a516.096 516.096 0 0 1 7.168 61.44v283.648a80.896 80.896 0 0 1-17.408 47.104 479.232 479.232 0 0 1-163.84 3.072c-37.888-24.064-14.336-64.512-26.624-97.28H268.613632c-9.728 34.816-15.36 93.184-26.624 97.28a486.4 486.4 0 0 1-167.936 0 91.648 91.648 0 0 1-18.432-54.272C52.549632 819.2 52.549632 780.288 52.549632 740.352c0-74.24 0-148.48 3.072-223.232L1.349632 502.784a58.88 58.88 0 0 1 0-25.088C16.709632 436.736 37.189632 404.992 89.925632 409.6c6.144 0 17.92-12.288 21.504-21.504a1378.304 1378.304 0 0 0 51.2-139.776 174.08 174.08 0 0 1 153.6-126.464c28.16-5.12 57.344-8.704 85.504-13.312zM176.965632 427.008a1617.408 1617.408 0 0 0 669.696 0c-18.944-56.832-37.888-107.52-51.2-158.72a84.992 84.992 0 0 0-84.992-68.096c-105.984-4.608-212.48-8.192-318.464-9.216a453.12 453.12 0 0 0-102.4 15.872 58.368 58.368 0 0 0-37.376 25.6C223.557632 294.4 202.053632 358.4 176.965632 427.008z m43.52 241.664A249.856 249.856 0 0 0 275.269632 665.6a51.2 51.2 0 0 0 33.28-25.088c2.56-7.68-9.216-26.624-19.456-31.232a1196.544 1196.544 0 0 0-128-51.2c-21.504-7.168-46.08-5.632-51.2 24.576-10.24 44.544 0 69.12 33.28 76.288 26.112 5.12 51.2 6.144 79.872 8.704z m590.336 0a730.624 730.624 0 0 0 80.384-10.24c38.4-8.704 30.208-39.936 29.184-67.072s-15.36-39.936-36.352-33.792a1110.016 1110.016 0 0 0-153.6 58.368c-22.528 10.752-14.336 40.448 11.264 46.08a576.512 576.512 0 0 0 69.12 6.144z"
                        p-id="6036"
                        :fill="carType == 'advance' ? '#A96C3D' : '#ffffff'"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="step-intro"
               :class="{advance: carType == 'advance'}">
            选择1辆空车
          </div>
        </div>
        <div class="row-arrow-wrap">
          <div class="row-arrow">
            <div class="line"
                 :class="{advance: carType == 'advance'}"></div>
            <div class="arrow"
                 :class="{advance: carType == 'advance'}"></div>
          </div>
          <div class="step-intro">
            你看不见我
          </div>
        </div>
        <div class="step">
          <div class="step-icon-wrap">
            <div class="step-icon-outer"
                 :class="{advance: carType == 'advance'}">
              <div class="step-icon">
                <svg t="1615792062708"
                     class="key-icon"
                     viewBox="0 0 1024 1024"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     p-id="8272"
                     width="200"
                     height="200">
                  <path d="M655.54431073 120.1012469c-138.4177282 0-251.20254378 112.78481557-251.20254378 251.20254378 0 16.51898815 1.70886084 33.03797627 5.12658253 49.55696442l2.27848111 11.96202589-295.06330537 295.06330538v176.58228702h206.20254159v-102.53165053h96.26582744V705.67089542h95.12658687v-96.8354477h32.468356v-9.1139245l29.6202546 9.68354477c25.06329235 8.54430421 51.83544554 12.53164617 78.03797845 12.53164617 138.4177282 0 251.20254378-112.78481557 251.20254377-251.20254377 1.13924057-137.84810792-111.64557501-250.6329235-250.06330321-250.63292349z m93.98734631 210.18988357c0 29.05063432-23.92405179 52.9746861-52.97468611 52.97468611s-52.9746861-23.92405179-52.9746861-52.97468611 23.92405179-52.9746861 52.9746861-52.9746861 52.9746861 23.35443151 52.97468611 52.9746861z"
                        :fill="carType == 'advance' ? '#A96C3D' : '#ffffff'"
                        p-id="8273"></path>
                </svg>
                <svg t="1615792118567"
                     class="coin-icon"
                     viewBox="0 0 1024 1024"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     p-id="9098"
                     width="200"
                     height="200">
                  <path d="M513 63.8c-246.5 0-446.3 199.8-446.3 446.3S266.5 956.4 513 956.4s446.3-199.8 446.3-446.3S759.5 63.8 513 63.8z m163.4 297.4L596.6 441h34.9c19.1 0 34.6 15.5 34.6 34.6s-15.5 34.6-34.6 34.6h-83.9v44.2h83.9c19.1 0 34.6 15.5 34.6 34.6s-15.5 34.6-34.6 34.6h-83.9v96c0 19.1-15.5 34.6-34.6 34.6s-34.6-15.5-34.6-34.6v-96h-83.9c-19.1 0-34.6-15.5-34.6-34.6s15.5-34.6 34.6-34.6h83.9v-44.2h-83.9c-19.1 0-34.6-15.5-34.6-34.6s15.5-34.6 34.6-34.6h34.9l-79.8-79.8c-13.5-13.5-13.5-35.4 0-49 13.5-13.5 35.4-13.5 49 0L513 426.7l114.4-114.4c13.5-13.5 35.4-13.5 49 0 13.5 13.5 13.5 35.4 0 48.9z"
                        p-id="9099"
                        :fill="carType == 'advance' ? '#A96C3D' : '#ffffff'"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="step-intro"
               :class="{advance: carType == 'advance'}">
            支付包车费
          </div>
        </div>
        <div class="row-arrow-wrap">
          <div class="row-arrow">
            <div class="line"
                 :class="{advance: carType == 'advance'}"></div>
            <div class="arrow"
                 :class="{advance: carType == 'advance'}"></div>
          </div>
          <div class="step-intro">
            你看不见我
          </div>
        </div>
        <div class="step">
          <div class="step-icon-wrap">
            <div class="step-icon-outer"
                 :class="{advance: carType == 'advance'}">
              <div class="step-icon">
                <svg t="1615792019100"
                     class="icon"
                     viewBox="0 0 1024 1024"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     p-id="7373"
                     width="200"
                     height="200">
                  <path d="M1023.200312 43.682936L877.057399 920.640375c-1.899258 10.995705-8.096837 19.592347-18.292854 25.689965-5.29793 2.898868-11.295588 4.598204-17.693089 4.598204-4.19836 0-8.796564-0.99961-13.69465-2.898868l-236.707536-96.762202c-12.994924-5.29793-27.889106-1.499414-36.785631 9.296368l-123.251855 150.341273c-6.897306 8.796564-16.293635 13.094885-27.989066 13.094885-4.898087 0-9.096447-0.799688-12.695041-2.299102-7.197189-2.698946-12.994924-6.997267-17.393206-13.394768-4.398282-6.29754-6.697384-13.194846-6.697384-20.891839V811.083171c0-14.794221 5.098009-28.988676 14.394377-40.484186l478.912925-587.070676-602.864506 521.796174c-4.598204 3.898477-10.995705 4.998048-16.493557 2.698945L23.390863 619.358063C9.296369 614.060133 1.599375 603.664194 0.599766 587.870363c-0.799688-15.194065 5.29793-26.489652 18.292854-33.786802L968.921515 5.997657c5.797735-3.498633 11.795392-5.098009 18.292854-5.098008 7.696993 0 14.594299 2.199141 20.691918 6.397501 12.695041 8.996486 17.593128 21.291683 15.294025 36.385786z"
                        p-id="7374"
                        :fill="carType == 'advance' ? '#A96C3D' : '#ffffff'"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="step-intro"
               :class="{advance: carType == 'advance'}">
            选择自己的车 并邀请乘客
          </div>
        </div>
      </div>
      <div class="car-list">
        <div class="car-box"
             :key="idx"
             :class="{advance: carType == 'advance'}"
             v-for="(car, idx) in charteredCars">
          <div class="car-owner"
               v-if="car.owner">
            <div class="car-owner-label">我的包车</div>
            <div class="car-owner-highlight"
                 :style="{'background': carType == 'advance' ? '#F5CB77' : '#1D9C82'}"></div>
          </div>
          <div class="car-passenger-wrap">
            <div class="car-passenger"
                 :key="index"
                 v-for="(passenger, index) in car.passengers">
              <div class="avatar">
                <img :src="
                    require('../../../assets/avatar' + (index % 5) + '.png')
                  "
                     v-if="passenger.email" />
                <img class="idle"
                     src="../../../assets/idle-seat.svg"
                     v-else />
              </div>
              <div class="email"
                   :class="{ idle: !passenger.email }">
                {{ passenger.email ? passenger.email : '空闲座位' }}
              </div>
              <div class="board-at"
                   :class="{
                  owner: index == 0,
                  idle: !passenger.email,
                  review: passenger.status == 3 || passenger.status == 7,
                  advance: carType == 'advance'
                }">
                {{
                passenger.owner
                ? '我'
                : index == 0
                ? '司机'
                : passenger.status == 3 || passenger.status == 7
                ? '等待中'
                : passenger.status == 6
                ? '上车中'
                : boardTime(passenger.boardAt)
                }}
              </div>
            </div>
            <div @click="charter(car.carId)"
                 class="car-passenger"
                 :style="{'background': carType == 'advance' ? '#F5CB77' : '#5BD7BF'}"
                 style="border-radius:0.04rem;position:relative;height: 1.16rem;"
                 v-if="!car.owner && !car.passengers[0].email">
              <span style="font-size: .28rem;position:absolute;width:.58rem;height:.8rem;color:white;">点击包车</span>
              <img class="idle"
                   src="../../../assets/car.svg"
                   style="border-radius: 0;position:absolute;top:.14rem;right:-0.08rem;height:1.2rem;width:1rem;opacity:0.4" />
            </div>
            <div class="car-passenger"
                 @click="inviteMember(car)"
                 style="border-radius:0.04rem;position:relative;background:#F5CB77;height: 1.16rem;"
                 v-if="car.owner">
              <span style="font-size: .28rem;position:absolute;width:.58rem;height:.8rem;color:white;">邀请乘客</span>
            </div>
            <div class="car-passenger"
                 @click="charteredPrompt()"
                 v-if="!car.owner && car.passengers[0].email"
                 style="border-radius:0.04rem;position:relative;background:#F3F3F3;height: 1.16rem;">
              <span style="font-size: .28rem;position:absolute;width:.58rem;height:.8rem;color:#D5D5D5;">已有司机</span>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cars"
           v-if="hasMoreCharteredCars">
        <div class="more-btn"
             :style="{'color': carType == 'advance' ? '#F5CB77' : '#5BD7BF'}"
             @click="moreCharteredCars">查看更多</div>
      </div>
    </div>
    <Toast :showToast="showToast"
           :message="toastMsg"
           @disappear="showToast = false"></Toast>
  </div>
</template>

<script>
// @ is an alias to /src
import { getCharteredCars, getPlans } from '@/api/carpool/index'
import { newOrder } from '@/api/carpool/order'
import Loading from '@/components/Loading'
import Toast from '@/components/Toast'
import { reportError } from '@/utils/log'
import { utils } from '@/utils'
import { events } from '@/api/common'

export default {
  name: 'Home',
  data() {
    return {
      toastMsg: '',
      showToast: false,
      plansDisplay: false,
      passenger: {
        email: '',
        zhimafenImgUrl: '',
      },
      showLoading: false,
      carId: 0,
      hasMoreCharteredCars: false,
      charteredCars: [],
      charteredCarsCurPage: 1,
      charteredCarsPerPage: 3,
      charteredCarSkuId: 0,
      carType: 'general',
    }
  },
  components: {
    loading: Loading,
    Toast,
  },
  watch: {
    '$route.query': async function(newV) {
      if (newV.type) {
        this.charteredCarsCurPage = 1
        this.carType = newV.type
        this.charteredCars = []
        await this.getPlans()
        this.getCharteredCars()
      }
    },
  },
  methods: {
    async getPlans() {
      try {
        let res = await getPlans({
          type: this.carType == 'general' ? ['包车'] : ['高级包车'],
        })
        if (res.data.code == 0) {
          if (res.data.plans.length) {
            this.charteredCarSkuId = res.data.plans[0].id
          }
        }
      } catch (error) {
        reportError(error)
      }
    },
    inviteMember(car) {
      if (car.passengers[0].status == 4) {
        this.$router.push('/carpool/baoche/invite-member?carId=' + car.carId)
      } else {
        this.toastMsg = '请司机先接受邮件邀请'
        this.showToast = true
      }
    },
    charteredPrompt() {
      this.toastMsg = '已有司机，请选择其他车'
      this.showToast = true
    },
    async charter(carId) {
      let ua = navigator.userAgent.toLowerCase()
      let isWeixin = /micromessenger/i.test(ua) || /windows phone/i.test(ua)
      if (!isWeixin) {
        this.toastMsg = '仅支持在微信端包车'
        this.showToast = true
        return
      }
      this.showLoading = true
      let orderParams = {
        skuId: this.charteredCarSkuId,
        carId,
        uuid: localStorage.getItem('uuid'),
        cf: localStorage.getItem('cf'),
      }
      let res = await newOrder(orderParams)
      this.showLoading = false
      if (res.data.code == 0) {
        this.$router.push(`/carpool/shangche?orderid=${res.data.order.orderId}`)
      } else {
        this.toastMsg = res.data.message
        this.showToast = true
        return
      }
    },
    boardTime(endDate) {
      if (endDate) {
        const endDay = new Date(endDate).getTime()
        const now = new Date().getTime()
        let num = Math.floor((now - endDay) / (24 * 3600 * 1000))
        let res = ''
        if (num === 0) {
          res = '上车1天'
        } else {
          if (num < 0) {
            num = 0
          }
          res = '上车' + (num + 1) + ' 天'
        }
        return res
      }
    },
    moreCharteredCars() {
      this.charteredCarsCurPage += 1
      this.getCharteredCars()
    },
    async getCharteredCars() {
      let res = await getCharteredCars({
        curPage: this.charteredCarsCurPage,
        perPage: this.charteredCarsPerPage,
        uuid: localStorage.getItem('uuid'),
        type: this.carType,
      })

      if (res.data.code == 0) {
        if (res.data.cars.length < this.perPage) {
          this.hasMoreCharteredCars = false
        } else {
          this.hasMoreCharteredCars = true
        }
        if (this.charteredCarsCurPage == 1) {
          res.data.cars = res.data.cars.map((car) => {
            for (let p of car.passengers) {
              if (p.uuid == localStorage.getItem('uuid')) {
                p.owner = true
                car.owner = true
                if (p.status == 4) {
                  car.ready = true
                }
                break
              }
            }
            return car
          })
        }
        if (res.data.cars.length) {
          this.charteredCars = this.charteredCars.concat(res.data.cars)
        } else {
          this.hasMoreCharteredCars = false
        }
      }
    },
  },
  async created() {
    if (this.$route.query.type) {
      this.carType = this.$route.query.type
    }
    await this.getPlans()
    this.getCharteredCars()
  },
}
</script>

<style lang="less" scoped>
#baoche {
  width: 7.5rem;
  background: linear-gradient(180deg, #5bd7be 0%, #4ac1ff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.advance {
    background: linear-gradient(180deg, #f7dda2 0%, #fac7b3 100%);
  }
  .car-guide {
    margin-top: 0.24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7.02rem;
    .prompt {
      color: #ffffff;
      &.advance {
        color: #a96c3d;
      }
      font-size: 0.24rem;
      .prompt-text {
        color: inherit;
        margin-bottom: 0.06rem;
      }
    }
    .chart {
      margin-top: 0.36rem;
      display: flex;
      align-items: center;
      .step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1.4rem;
        height: 1.8rem;
        .step-icon-wrap {
          width: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .step-icon-outer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0.96rem;
            height: 0.96rem;
            border-radius: 50%;
            border: 0.02rem solid rgba(255, 255, 255, 0.45);
            &.advance {
              border: 0.02rem solid #a96c3d;
            }
            .step-icon {
              width: 0.9rem;
              height: 0.9rem;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.19);
              &.advance {
                background: rgba(169, 108, 61, 0.19);
              }
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              svg {
                width: 0.5rem;
                height: 0.5rem;
              }
              .key-icon {
                position: absolute;
                width: 0.5rem;
                height: 0.5rem;
                right: 0.24rem;
                bottom: 0.24rem;
              }
              .coin-icon {
                position: absolute;
                width: 0.24rem;
                height: 0.24rem;
                top: 0.4rem;
                left: 0.54rem;
              }
            }
          }
        }
        .step-intro {
          display: flex;
          margin-top: 0.08rem;
          font-size: 0.22rem;
          color: #ffffff;
          &.advance {
            color: #a96c3d;
          }
          height: 0.64rem;
        }
      }
      .row-arrow-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 1.68rem;
        .row-arrow {
          width: 143%;
          margin-left: 0.09rem;
          height: 0.96rem;
          display: flex;
          align-items: center;
          .line {
            height: 0.02rem;
            width: 100%;
            background: #ffffff;
            &.advance {
              background: #a96c3d;
            }
          }
          .arrow {
            border: 0.08rem solid transparent;
            border-left: 0.14rem solid #ffffff;
            &.advance {
              border-left: 0.14rem solid #a96c3d;
            }
            width: 0;
            height: 0px;
          }
        }
        .step-intro {
          margin-top: 0.04rem;
          font-size: 0.22rem;
          color: white;
          visibility: hidden;
          height: 0.32rem;
        }
      }
    }
    .car-list {
      margin-top: 0.24rem;
      width: 7.02rem;
      display: flex;
      flex-direction: column;
      .car-box {
        margin-bottom: 0.16rem;
        display: flex;
        background: white;
        flex-direction: column;
        width: 7.02rem;
        border-radius: 0.08rem;
        box-shadow: 0px 0px 0.08rem 0px #24a99b;
        &.advance {
          box-shadow: 0px 0px 0.08rem 0px #a96c3d;
        }
        .car-owner {
          display: flex;
          width: 1.4rem;
          margin-left: 0.24rem;
          margin-top: 0.24rem;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #24a99b;
          &.advance {
            color: #a96c3d;
          }
          position: relative;
          .car-owner-label {
            font-weight: 400;
            font-size: 0.28rem;
            z-index: 1;
          }
          .car-owner-highlight {
            z-index: 0;
            width: 80%;
            margin-top: -0.1rem;
            height: 0.08rem;
            background: #5bd7bf;
            &.advance {
              background: #f5cb77;
            }
            border-radius: 0.05rem;
          }
        }
        .car-passenger-wrap {
          flex-shrink: 0;
          width: 7.02rem;
          height: 4.6rem;
          display: flex;
          flex-wrap: wrap;
          align-content: space-around;
          .car-passenger {
            width: 1.16rem;
            display: flex;
            margin-left: 0.54rem;
            flex-direction: column;
            cursor: pointer;
            &:first-child,
            &:nth-child(5) {
              margin-left: 0.38rem;
            }
            align-items: center;
            justify-content: center;
            .avatar {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #f3f3f3;
              width: 1.16rem;
              height: 1.16rem;
              border-radius: 50%;
              img {
                width: 1.16rem;
                height: 1.16rem;
                border-radius: 50%;
                &.idle {
                  width: 0.7rem;
                  height: 0.7rem;
                  cursor: pointer;
                }
              }
            }
            .email {
              font-size: 0.2rem;
              color: #333333;
              height: 0.38rem;
              width: 1.14rem;
              margin-top: 0.08rem;
              display: flex;
              align-items: center;
              overflow-y: hidden;
              overflow-x: auto; //超出的文本隐藏
              overflow-x: overlay; //这个值不会占用滚动条的空间
              text-overflow: ellipsis; //用省略号显示
              &::-webkit-scrollbar {
                width: 0 !important;
              }
              white-space: nowrap; //不换行
              &.idle {
                color: #b0b0b0;
                justify-content: center;
              }
            }
            .board-at {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #5ad6be;
              border-radius: 0.04rem;
              font-size: 0.2rem;
              color: white;
              width: 1.1rem;
              height: 0.32rem;
              &.advance {
                background: #f5cb77;
              }
              &.idle {
                visibility: hidden;
              }
              &.owner {
                background: #f37e74;
              }
            }
          }
        }
      }
    }
    .more-cars {
      margin-top: 0.22rem;
      margin-bottom: 0.38rem;
      width: 7.02rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .more-btn {
        width: 2.1rem;
        height: 0.7rem;
        background: #ffffff;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f5cb77;
        font-size: 0.28rem;
      }
    }
  }
}
</style>
